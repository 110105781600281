import { createSlice } from "@reduxjs/toolkit";
import { CartJson } from "@/types/Cart.type";
import { StoreJson } from "@/types/Store.type";

type InitialState = {
	activeIndex: number;
	storeDetail: StoreJson | null;
};
const initialState: InitialState = {
	activeIndex: -1,
	storeDetail: null,
};

const PosSlice = createSlice({
	name: "order",
	initialState: initialState,
	reducers: {
		setActiveInidex: (state, action) => {
			state.activeIndex = action.payload;
		},
		setStoreDetail: (state, action) => {
			state.storeDetail = action.payload;
		},
	},
});

export const { setActiveInidex, setStoreDetail } = PosSlice.actions;

export default PosSlice.reducer;
